<template>
        <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
          <router-link class="navbar-brand" aria-current="page" to="/"><img class="logoEntreprise" src="../assets/images/logo_sasu.png" alt=""></router-link> 
          <button class="navbar-toggler navbar-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent" >
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <router-link class="nav-link"  aria-current="page" to="/">Accueil</router-link> 
                  </li>

                  <li class="nav-item" v-for="mission in this.missions">
                    <router-link 
                    class="nav-link"
                    :key="mission.id"
                    :to="`/mission/${mission.id}/${mission.slug}`">{{ mission.client }}</router-link> 
                  </li>
                 
            </ul>
          </div>
        </div>
      </nav>
</template>

<script>
import datas from '@/datas.json'

export default {
 data(){
  return {
    missions: datas.missions
  }
 }
}

</script>

<style scoped>
img.logoEntreprise{
  width:150px;
  height: 40px;
  background-size: cover; 
  border-radius: 5px;
}
nav {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
